body{
 overflow-x: hidden !important;
}


@font-face {
    font-family:montserrat_bold;
    src: url('../public/Montserrat-Bold.ttf');
  }
  
  
  @font-face {
    font-family:montserrat;
    src: url('../public/Montserrat-Medium.ttf');
  }
  
  .font_bold{
    font-family:montserrat_bold;
  }
  
  .font_normal{
    font-family:montserrat;
  }
  

#font_heading{
  font-family:montserrat_bold !important; 
  font-size:20px;
  margin: 20px 0px;
}

#font_sub_heading{
  font-family:montserrat_bold !important;
  font-size:14px;
  margin: 20px 0px;
}

#font_description{
  font-family:montserrat !important;
  font-size:14px;
  margin: 20px 0px;
}
  
#bolder{
  font-family:montserrat_bold !important;
}

#list-section{
  margin-left: 40px !important;
  list-style-type: circle;
}

#img_section{
  width: 100% !important;
}

  .ant-pagination-item-active a{
    color: black !important;
    border:1px solid #eee;
    padding: 0px 2px;
    border-radius: 4px;
    font-weight: 400;
  }
  
  .ant-pagination-item-active a:hover{
    border-radius: 0px !important;
  }
  
  .ant-pagination-item-active{
    border: none !important;
  }
  
  .ant-tooltip-open{
    color: black !important;
  }